<template>
  <page-content>

    <common-table
      ref="table"
      path="vendor"
      :columns="columns">

      <template slot="search">
        <a-button ghost type="primary" @click="add">新增</a-button>
      </template>

      <template slot="level" slot-scope="{record}">
        <a-tag color="#33ccff" v-if="record.level==1">代理商</a-tag>
        <a-tag color="#33cc66" v-else-if="record.level==2">经销商</a-tag>
        <a-tag color="#BFBFBF" v-else-if="record.level==3">客户</a-tag>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-view @click="vendorDevices(record)"></action-view>
        <action-edit @click="edit(record)"></action-edit>
        <action-delete @click="remove(record)"></action-delete>
      </template>

    </common-table>

    <vendor-edit ref="vendorEdit" @success="getList" ></vendor-edit>

  </page-content>
</template>
<script>
import VendorEdit from './VendorEdit'

export default {
  components: { VendorEdit},
  data () {
    return {

    }
  },
  created () {

  },
  computed: {
    columns () {
      return [
        {
          title: '登录账号',
          dataIndex: 'account'
        },
        {
          title: '经销商名称',
          dataIndex: 'name',
          ellipsis:true
        },
        // {
        //   title: '公司地址',
        //   dataIndex: 'address',
        //   ellipsis:true
        // },
        {
          title: '联系人',
          dataIndex: 'contactPerson',
          ellipsis:true
        },
        {
          title: '联系方式',
          dataIndex: 'contactNumber'
        },
        // {
        //   title: '类型',
        //   dataIndex: 'level',
        //   scopedSlots: { customRender: 'level' },
        // },
        // {
        //   title: '设备数',
        //   dataIndex: 'deviceCount'
        // },
        // {
        //   title: '添加时间',
        //   dataIndex: 'createdAt'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width:100
        }
      ]
    }
  },
  methods: {
    add () {
      this.$refs.vendorEdit.show()
    },
    edit(record){
      this.$refs.vendorEdit.show(record)
    },
    remove(record){
      let that = this
      this.$confirm({
        title: '是否删除该经销商?',
        content: '该操作不可撤销',
        centered: true,
        onOk () {
          that.$delete('vendor/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },

    vendorDevices(record){
      let vendorId = record.id
      // this.$router.push(`/vendor/${vendorId}/product`)
      this.$router.push(`/vendor/${vendorId}/device`)
    },

    getList () {
      this.$refs.table.getData()
    }

  }
}
</script>
<style lang="less" scoped>

</style>
